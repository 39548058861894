<template>
  <svg
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.17422 7H8.82578C9.68013 7 10.141 5.99788 9.58504 5.34921L5.75926 0.885799C5.36016 0.420188 4.63984 0.420188 4.24074 0.8858L0.414964 5.34921C-0.141042 5.99788 0.319867 7 1.17422 7Z"
      fill="currentColor"
    />
  </svg>
</template>
